import React, { useRef, useState, useEffect } from 'react';

import './Investigation.css';
import { saveFormData } from '../../requests/form';

import img_desktop from '../../Assets/Images/1440x782/banner_inv.jpg';
import img_tablet from '../../Assets/Images/1600x900/Investigacion.jpg';
import img_mobile from '../../Assets/Images/1080x920/Investigacion.jpg';
import img_1 from '../../Assets/Images/1080x920/Mesa-de-trabajo-8.png';
import img_2 from '../../Assets/Images/1080x920/Mesa-de-trabajo-9.png';
import img_3 from '../../Assets/Images/1080x920/card_simbiotico_nin.png';
import malteada from '../../Assets/Images/Malteada-Nutritiva-vainilla-490g_1.jpg'
import inulina from '../../Assets/Images/inulina_agave_1.jpg'
import simbiotioco from '../../Assets/Images/Simbiotico.jpg'

//Protocolos
import malteada_protocol from '../../Assets/Resources/protocolos/Malteada_Nutritiva_NIN_preliminar_junio-2024.pdf';
import inulina_protocol from '../../Assets/Resources/protocolos/Inulina_de_agave_NIN_Preliminar_junio-2024.pdf';
import simbiotico_protocol from '../../Assets/Resources/protocolos/reporte_preliminar_simbiotico_nin.pdf';

//MUI
import {
    Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, FormControl, FormControlLabel, Link as MUILink,
    FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography,
    List, Divider, IconButton, useMediaQuery
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { Link } from 'react-router-dom';

const prodProt = [
    {
        id: 1,
        name: 'Malteada nutritiva NIN',
        img: img_1,
        img_1: malteada,
        protocol: malteada_protocol,
        date: 'Julio 23, 2024'
    },
    {
        id: 2,
        name: 'Inulina de agave NIN',
        img: img_2,
        img_1: inulina,
        protocol: inulina_protocol,
        date: 'Julio 23, 2024'
    },
    {
        id: 3,
        name: 'Simbiotico NIN',
        img: img_3,
        img_1: simbiotioco,
        protocol: simbiotico_protocol,
        date: 'Septiembre 25, 2024'
    },
];

const specialtiesArray = [
    'Nefrología', 'Nutriología', 'Urología', 'Cardgiología', 'Endocrinología', 'Otro'
];

const init = {
    especialidad: '',
    especialidad2: '',
    email: '',
    terms: ''
};

const email_regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

const initErr = {
    esp_err: false,
    esp2_err: false,
    email_err: false,
    terms_err: false
};

const Investigation = () => {
    const matches = useMediaQuery('(max-width: 506px)')

    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    const [user, setUser] = useState(init);
    const { especialidad, especialidad2, email, terms } = user;

    const [inputErr, setInputErr] = useState(initErr);
    const { esp_err, esp2_err, email_err, terms_err } = inputErr;

    const [action, setAction] = useState(true);

    const esp_ref = useRef();
    const esp2_ref = useRef();
    const email_ref = useRef();

    useEffect(() => {
        if (open) {
            const form_email = localStorage.getItem('form_email');
            if (form_email) {
                setUser(e => ({ ...e, email: form_email }));
                setAction(false);
            } else
                setAction(true);
        }
    }, [open]);

    const onChange = ({ target }) => {
        const name = target.name
        const value = target.value;
        setUser(e => ({
            ...e,
            [name]: value
        }));
        switch (name) {
            case 'especialidad':
                setInputErr({
                    ...inputErr,
                    esp_err: (value.length < 2 ||
                        value.length > 60) ? true : false
                });
                break;
            case 'especialidad2':
                setInputErr({
                    ...inputErr,
                    esp2_err: (value.length < 2 ||
                        value.length > 60) ? true : false
                });
                break;
            case 'email':
                setInputErr({
                    ...inputErr,
                    email_err: (value.length < 6 ||
                        value.length > 120) ? true : false
                });
                break;
            default:
                break;
        };
    };

    const onCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setUser(e => ({
            ...e,
            terms: checked
        }));
        switch (e.target.name) {
            case 'terms':
                setInputErr({
                    ...inputErr,
                    terms_err: (value === false) ? true : false
                });
                break;
            default:
                break;
        }
    };

    const formValidator = () => {
        const esp_len = especialidad.trim().length;
        const esp2_len = especialidad2.trim().length;
        const email_len = email.trim().length;
        if (esp_len > 2 && esp_len <= 60) {
            if (especialidad === 'Otro') {
                if (esp2_len > 2 && esp2_len <= 60)
                    if (email_len > 5 && email_len <= 120 && email_regex.test(email)) {
                        if (terms === true) {
                            return true
                        } else {
                            setInputErr({
                                ...inputErr,
                                terms_err: true
                            });
                        }
                    } else {
                        email_ref.current.select();
                        setInputErr({
                            ...inputErr,
                            email_err: true
                        });
                    }
                else
                    setInputErr({
                        ...inputErr,
                        esp2_err: true
                    });
            } else {
                if (email_len > 5 && email_len <= 120 && email_regex.test(email)) {
                    if (terms === true) {
                        return true
                    } else {
                        setInputErr({
                            ...inputErr,
                            terms_err: true
                        });
                    }
                } else {
                    email_ref.current.select();
                    setInputErr({
                        ...inputErr,
                        email_err: true
                    });
                }
            };
        } else {
            setInputErr({
                ...inputErr,
                esp_err: true
            });
        }
        return false;
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (formValidator()) {
            setOpen(false);
            await saveFormData({ ...user, protocol: modalData.name });
            setUser(init);
            //Descargar PDF
            // const pdfUrl = modalData.protocol;
            // const link = document.createElement("a");
            // link.href = pdfUrl;
            // link.download = `Protocolo ${modalData.name}.pdf`;
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);

            //Sólo visualizar pdf
            const link = document.createElement("a");
            link.setAttribute("href", modalData.protocol);
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noopener noreferrer");
            document.body.appendChild(link);
            setTimeout(() => {
                link.click();
                document.body.removeChild(link);
            }, 70);
        }
    };

    return (
        <>
            <div className="position-relative-inv">
                <Box>
                    <div className='banner-inv-desktop'>
                        <img src={img_desktop} alt={img_desktop} width='100%' />
                    </div>

                    <div className='banner-inv-tablet'>
                        <img src={img_tablet} alt={img_tablet} width='100%' />
                    </div>

                    <div className='banner-inv-mobile'>
                        <img src={img_mobile} alt={img_mobile} width='100%' />
                    </div>
                </Box>

                <div className="position-absolute-inv image-container-inv">
                    <div className='card_floating-inv'></div>
                </div>
            </div>

            <Container maxWidth='xl' sx={{ mb: 6, mt: 2 }} id='inv_page'>
                <Typography variant='body1' paragraph>
                    Nuestro equipo de investigación se ha dedicado a examinar de cerca el impacto de diversas sustancias nutricionales en la enfermedad
                    renal crónica. A través de rigurosos estudios y análisis clínicos, hemos reunido una colección de artículos científicos fundamentales
                    que arrojan luz sobre los siguientes aspectos clave:
                </Typography>
                <Typography variant='body1' paragraph>
                    La influencia de componentes alimenticios específicos en la función renal y la salud general del riñón.
                </Typography>
                <Typography variant='body1' paragraph>
                    El efecto de los compuestos presentes en suplementos alimenticios en la progresión de enfermedades crónicas como la enfermedad renal
                    y su capacidad para mejorar la calidad de vida de los pacientes.
                </Typography>
                <Typography variant='body1' paragraph>
                    Nuestra investigación refleja un compromiso continuo con la comprensión profunda de la enfermedad renal y la salud en general, con el
                    desarrollo de soluciones que puedan proporcionar un impacto positivo significativo en la vida de las personas.
                </Typography>
                <Typography variant='body1' paragraph>
                    Para acceder a estos artículos científicos y explorar más a fondo nuestra investigación en este campo, consulta aquí y no dudes en
                    ponerte en contacto con nuestro equipo.
                </Typography>

                <Box sx={{ my: 5 }}>
                    <Typography paragraph sx={{ fontWeight: '600', fontSize: '28px' }}>Protocolos:</Typography>
                    <List sx={{ width: '100%' }}>
                        {
                            prodProt.map((data) => (
                                <div key={data.id}>
                                    <Box className='protocol_container'>
                                        <Box className='protocol_img_name'>
                                            <Box sx={{ my: 'auto' }}>
                                                <img src={data.img_1} alt={data.img_1} width='80px' style={{ pointerEvents: 'none' }} />
                                            </Box>
                                            <Box sx={{ my: 'auto' }}>
                                                <Typography variant='body1' sx={{ fontWeight: '600' }}>{`${data.id}. Protocolo ${data.name}`}</Typography>
                                                <Typography variant='body1' color='text.secondary'>Fecha de publicación: {data.date}</Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ my: 'auto' }}>
                                            {
                                                matches
                                                    ?
                                                    <Button
                                                        variant='outlined'
                                                        sx={{ textTransform: 'none' }}
                                                        endIcon={<DownloadIcon />}
                                                        onClick={() => {
                                                            setModalData(data);
                                                            setOpen(true);
                                                        }}
                                                    >
                                                        Descargar protocolo
                                                    </Button>
                                                    :
                                                    <IconButton edge="start"
                                                        onClick={() => {
                                                            setModalData(data);
                                                            setOpen(true);
                                                        }}
                                                    >
                                                        <DownloadIcon />
                                                    </IconButton>
                                            }
                                        </Box>
                                    </Box>
                                    <Divider sx={{ my: 3 }} />
                                </div>
                            ))
                        }
                    </List>
                </Box>

                {/* <Grid container spacing={5} sx={{ textAlign: 'center' }}>
                {
                    prodProt.map((data) => (
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6} key={data.id}>
                            <img
                                src={data.img}
                                alt={data.img}
                                width='50%'
                                className='inv_img_card'
                                onClick={() => {
                                    setModalData(data);
                                    setOpen(true);
                                }}
                            />
                        </Grid>
                    ))
                }
            </Grid> */}
            </Container>

            {/*Formulario para protocolos*/}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth='sm'
            >
                <DialogTitle>Protocolo de {modalData.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Material exclusivo para el profesional de la salud
                    </DialogContentText>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant='outlined' sx={{ mt: 2 }}>
                                <InputLabel>Especialidad</InputLabel>
                                <Select
                                    value={especialidad}
                                    label="Especialidad"
                                    onChange={onChange}
                                    name='especialidad'
                                    error={esp_err}
                                    inputRef={esp_ref}
                                >
                                    {
                                        specialtiesArray.map((res, index) =>
                                            <MenuItem key={index}
                                                value={res}>
                                                {res}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            especialidad === 'Otro'
                                ?
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name='especialidad2'
                                            value={especialidad2}
                                            onChange={onChange}
                                            label="Escriba la especialidad"
                                            error={esp2_err}
                                            helperText={esp2_err ? "Este campo es requerido" : ""}
                                            inputRef={esp2_ref}
                                            variant="outlined" />
                                    </FormControl>
                                </Grid>
                                :
                                null
                        }
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    name='email'
                                    value={email}
                                    onChange={onChange}
                                    label="Correo electrónico"
                                    error={email_err}
                                    helperText={email_err ? "Ingrese su correo electrónico" : ""}
                                    inputRef={email_ref}
                                    InputProps={{
                                        style: { textTransform: 'lowercase' }
                                    }}
                                    variant="outlined" />
                            </FormControl>
                            {
                                !action &&
                                <MUILink
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        setUser(e => ({ ...e, email: '' }));
                                        setAction(true);
                                        localStorage.removeItem('form_email');
                                    }}
                                >
                                    Este no es mi correo
                                </MUILink>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox value={terms} onChange={onCheckboxChange} name='terms' />}
                                label={
                                    <Typography variant='body2'>
                                        Al confirmar manifiestas tu conformidad con
                                        nuestros <Link to="/terminos-y-condiciones" target="_blank" rel="noopener noreferrer">Términos y condiciones</Link> y
                                        nuestro <Link to="/aviso-de-privacidad" target="_blank" rel="noopener noreferrer">Aviso de Privacidad</Link>.
                                    </Typography>
                                }
                            />
                            <FormHelperText sx={{ color: 'red' }}>{terms_err ? 'Debe aceptar los terminos y condiciones' : ''}</FormHelperText>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'none' }} onClick={() => setOpen(false)}>Cancelar</Button>
                    <Button sx={{ textTransform: 'none' }} onClick={handleSubmit}>
                        Descargar protocolo
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Investigation